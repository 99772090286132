import React, { useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from "prop-types"
import tw, { styled, css } from 'twin.macro'
import LogoImageOne from '../../../assets/svgs/Logo.svg'

// const TopButtonLink = styled(props => <Link {...props} />)`
//   ${tw`py-3 px-6 border-0 text-white outline-none font-bold font-lato tracking-brand`};
//   background-color: #254AC4;
//   outline: 0;
//   text-decoration: none;
//   cursor: pointer;
//   border: none;
// `;

const HomeLogoLink = styled(props => <Link {...props} />)`
  width: 179px;
  margin-right: 30px;

  @media (max-width: 1024px) {
    width: 140px;
  }

  @media (max-width: 450px) {
    width: 120px;
  }
  display: inline-block;

  & > svg {
    width: 100%;
    height: 100%;
  }
`
// const TopSection = styled.section`
//   ${tw`fixed w-full top-0 md:static bg-white flex justify-between items-center mx-auto my-0 py-6 max-w-screen-xl px-5 sm:px-10 medium:px-0`}
//   z-index: 5000;
// `
const HeaderWrapper = styled.div`
  ${tw`bg-brand-dark-200 fixed w-full`}
  z-index: 5000;
`

const HeaderContainer = styled.header`
  ${tw`fixed top-0 w-full md:static mx-auto bg-brand-dark-200 md:py-0 px-5 sm:px-10 medium:px-6 max-w-screen-xl flex justify-between items-center flex-wrap md:flex-nowrap`}
  z-index: 5000;
`

const HeaderToggler = styled.div`
  ${tw`block md:hidden cursor-pointer select-none py-10`}
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const HeaderIcon = styled.span`
  ${tw`bg-white block relative`};
  width: 30px;
  height: 3px;
  border-radius: 5px;

  ::before,
  ::after{
    ${tw`bg-white w-full h-full inline-block`}
    position: absolute;
    content: '';
    border-radius: 5px;
    transition: all .2s ease-out;
  }

  ::before {
    top: 7px;
  }

  ::after {
    top: -7px;
  }
  ${({ clicked }) =>
    clicked &&
    css`
      background: transparent;
      ::before,
      ::after {
        top: 0;
      }

      ::before{
        transform: rotate(-45deg);
      }

      ::after{
        transform: rotate(45deg);
      }
    `
  }
`

const HeaderElement = styled.div`
  ${tw`flex justify-between items-center w-full overflow-hidden w-full md:w-auto md:overflow-auto`}

  @media only screen and (max-width: 767.98px) {
    align-items: flex-start;
    max-height: 0;
    will-change: max-height;
    transition: max-height 0.2s ease-out;

    ${({ clicked }) =>
      clicked &&
      css`
        max-height: 386px;
        padding-bottom: 20px;
      `
    }
  }
`
const Navigation = styled.nav`
  ${tw`text-white uppercase md:flex justify-between font-bold tracking-brand`}
  font-size: 15px;
  line-height: 18px;
  width: 673px;

  @media only screen and (max-width: 767.98px) {
    width: 100%;
  }
`
const isActive = className => ({ isPartiallyCurrent }) => ({
  className: className + (isPartiallyCurrent ? ' active' : ''),
})

const HeaderLink = ({ className, children, ...props }) => (
  <Link getProps={isActive(className)} {...props}>
    {children}
  </Link>
)

const HomeLink = styled(props => <Link {...props} />)`
  ${tw`block md:inline py-4 md:py-8`}

  &.active {
    border-bottom: 4px solid #254ac4;
  }

  @media only screen and (max-width: 767.98px) {
    &.active {
      margin-bottom: 10px;
    }
  }
`

const NavLink = styled(HeaderLink)`
  ${tw`block md:inline py-4 md:py-8`}

  &.active {
    border-bottom: 4px solid #254ac4;
  }

  @media only screen and (max-width: 767.98px) {
    &.active {
      margin-bottom: 10px;
    }
  }
`

const Header = ({ siteTitle }) => {
  const [clicked, setClicked] = useState(false);
  return (
    <>
      <HeaderWrapper>
        <HeaderContainer>
          <HomeLogoLink to="/">
            <LogoImageOne />
          </HomeLogoLink>
          {/* Mobile Navigation Toggler/Icon*** Hidden by default on mobile */}
          <HeaderToggler clicked={clicked} onClick={() => setClicked(!clicked)}>
            <HeaderIcon clicked={clicked}/>
          </HeaderToggler>
          <HeaderElement clicked={clicked}>
            <Navigation>
              <HomeLink to="/" activeClassName="active">Home</HomeLink>
              <NavLink to="/about-us" activeClassName="active">About Us</NavLink>
              <NavLink to="/services" activeClassName="active">Services</NavLink>
              <NavLink to="/qhse" activeClassName="active">QHSE</NavLink>
              <NavLink to="/gallery" activeClassName="active">Gallery</NavLink>
              <NavLink to="/partnerships" activeClassName="active">Partnerships</NavLink>
              <NavLink to="/contact-us" activeClassName="active">Contact Us</NavLink>
            </Navigation>
          </HeaderElement>
        </HeaderContainer>
      </HeaderWrapper>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header