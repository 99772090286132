import React from 'react'
import { Link } from 'gatsby'
import tw, { styled } from 'twin.macro'
import FacebookIcon from '../../../assets/svgs/facebook.svg'
// import TwitterIcon from '../../../assets/svgs/twitter.svg'
import InstagramIcon from '../../../assets/svgs/instagram.svg'
import LinkedinIcon from '../../../assets/svgs/Linkedin.svg'
import WhatsappIcon from '../../../assets/svgs/whatsapp.svg'
import LocationIcon from '../../../assets/svgs/location.svg'
import TelephoneIcon from '../../../assets/svgs/telephone.svg'
import MailIcon from '../../../assets/svgs/mail.svg'
import LogoImageTwo from '../../../assets/images/Logo-2.png'

const Logo = styled.img`
  width: 290px;
  @media (max-width: 450px) {
    width: 230px;
  }
`

const FooterContainer = styled.footer`
  background-color: #000c1a;
`
const StyledFooter = styled.div`
  ${tw`flex-wrap big-tablet:flex-nowrap flex justify-between pb-20 pt-20 mx-auto px-5 sm:px-10 big-tablet:pb-0 xxl:px-0 xxl:max-w-footer`}
`

const FooterFirstSection = tw.section`order-2 big-tablet:order-1`;

const StyledLegalSection = styled.section`
  ${tw`xs:block flex justify-between w-full big-tablet:justify-start big-tablet:w-auto big-tablet:order-2`}

  & > *:not(:last-child) {
    ${tw`big-tablet:mr-21`}
  }

`

const StyledLegalBlock = tw.div`mb-10`

const StyledCopyrightText = styled.p`
  ${tw`xs:w-full text-white py-6 font-lato`}
  width: 380px;
  font-size: 15px;
  line-height: 126%;
`

const SocialIconsContainer = styled.div`
  ${tw`flex items-center mt-1`}
  & > *:not(:last-child) {
    padding-right: 12.5px;
  }
`

const FooterTitle = styled.h4`
  ${tw`text-white font-semibold mb-6 uppercase font-mulish`}
  font-size: 17.5431px;
  line-height: 22px;
`
const FooterLink = styled(props => <Link {...props} />)`
  ${tw`text-white block mb-3 font-lato no-underline`}
  font-size: 14.0345px;
  line-height: 17px;
`

const FooterFlexWrapper = styled.div`
  ${tw`flex items-start mb-15/2`}
`

const FooterAddressText = styled.p`
  ${tw`xs:w-full text-white pl-9/2`}
  font-size: 14px;
  font-weight: 500;
  width: 240px;
`

const FooterTelephoneLink = tw.a`
   text-white pl-9/2
`

const FooterSingleAddress = styled.p`
  ${tw`xs:w-full pl-9/2 text-white ml-auto mb-15/2`}
  font-size: 14px;
  font-weight: 500;
  width: 240px;
`

const Footer = () => {
  return (
    <FooterContainer>
      <StyledFooter>
        <FooterFirstSection>
          <Link to="/">
            <Logo src={LogoImageTwo} alt="Mainz Logo" />
          </Link>
          <StyledCopyrightText>
            The MANZ brand is a property of Manz Incorporated. <br/>
            © Copyrights, All right reserved by Manz Incorporated
          </StyledCopyrightText>
          <SocialIconsContainer>
            <a href="https://www.linkedin.com/company/manz-incorporated-limited" target="_blank" rel="noreferrer">
              <LinkedinIcon />
            </a>
            <a href="https://web.facebook.com/Manz-Incorporated-Limited-100324775605551" target="_blank" rel="noreferrer">
            <FacebookIcon />
            </a>
            <a href="https://www.instagram.com/manzincorporatedlimited/" target="_blank" rel="noreferrer">
              <InstagramIcon />
            </a>
            <a href="https://wa.link/aay81h" target="_blank" rel="noreferrer">
              <WhatsappIcon />
            </a>
            {/* <a href="https:www.twitter.com" target="_blank" rel="noreferrer">
              <TwitterIcon />
            </a> */}
          </SocialIconsContainer>
        </FooterFirstSection>
        <StyledLegalSection>
          <StyledLegalBlock>
            <FooterTitle>
              Company
            </FooterTitle>
            <FooterLink to="/about-us">
              About Us
            </FooterLink>
            <FooterLink to="/contact-us">
              Contact Us
            </FooterLink>
            <FooterLink to="/services">
              Our Services
            </FooterLink>
          </StyledLegalBlock>
          <StyledLegalBlock>
            <FooterTitle>
              Legal
            </FooterTitle>
            <FooterLink to="/privacy-policy">
              Privacy Policy
            </FooterLink>
            <FooterLink to="/terms">
              Terms
            </FooterLink>
          </StyledLegalBlock>
          <StyledLegalBlock>
            <FooterTitle>
              Company Address
            </FooterTitle>
            <FooterFlexWrapper>
              <LocationIcon />
              <FooterAddressText>
                4C Georges Close, by 5 Nkpogu Road, Off TRans Amadi Industrial Layout Port Harcourt, Rivers State, Nigeria
              </FooterAddressText>
            </FooterFlexWrapper>
            <FooterSingleAddress>
              28 Jehovah Witness Road, Bogije Lekki Epe Express way, Lagos.
            </FooterSingleAddress>
            <FooterFlexWrapper>
              <TelephoneIcon />
              <FooterTelephoneLink href="tel:2348023229864">+2348023229864</FooterTelephoneLink>
            </FooterFlexWrapper>
            <FooterFlexWrapper>
              <MailIcon />
              <FooterTelephoneLink href="mailto:sales@manzincorporated.com">sales@manzincorporated.com</FooterTelephoneLink>
            </FooterFlexWrapper>
          </StyledLegalBlock>
        </StyledLegalSection>
      </StyledFooter>
    </FooterContainer>
  )
}

export default Footer