import React from 'react'
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { styled } from 'twin.macro'
import Header from './Header/Header'
import Footer from './Footer/Footer'

const MainContainer = styled.main`
  min-height: 35vh;

  /* @media only screen and (max-width: 767.98px) { */
    padding-top: 76px;
  /* } */
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`}/>
      <MainContainer>
        {children}
      </MainContainer>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout